import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const InvestorBox = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.CHARCOAL};
  border-radius: 14px;
  padding: 88px 60px;
  gap: 44px;
  @media (max-width: 900px) {
    padding: 60px 40px;
  }
  @media (max-width: 470px) {
    padding: 60px 24px;
  }
`;

const InvestorBoxText = styled.div`
  display: flex;
  gap: 58px;
  color: ${colors.WHITE};
  p {
    color: ${colors.TEAL};
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  h3 {
    font-size: 44px;
    font-weight: 700;
    @media (max-width: 724px) {
      font-size: 32px;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const InvestorListCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  .active {
    border: 2px solid ${colors.TEAL};
    background: radial-gradient(
        199.28% 108.12% at 45.83% 155.56%,
        rgba(0, 242, 255, 0.1) 0%,
        rgba(0, 242, 255, 0) 100%
      ),
      ${colors.CHARCOAL};
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const InvestorCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 36px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  gap: 12px;
  width: 100%;
  border: 2px solid ${colors.CHARCOAL};
  cursor: pointer;
  img {
    width: 60px;
    height: 60px;
  }
  h3 {
    color: ${colors.WHITE};
    font-size: 24px;
    font-weight: 600;
  }
  p {
    color: ${colors.GRAY};
    font-size: 20px;
    font-weight: 400;
    max-width: 340px;
  }
  @media (max-width: 470px) {
    padding: 44px 26px;
  }
`;

export { InvestorBox, InvestorBoxText, InvestorListCard, InvestorCard };
