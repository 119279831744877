import {
  FooterBox,
  InfoBox,
  InfoDescription,
  BoxRow,
  MiniFooter,
  NetworkBox,
  Copyright,
  Line,
  IconInstagram,
  IconYoutube,
  IconTelegram,
  // IconFacebook,
  // IconTwitter,
} from './Footer.style';
import Container from 'index.style';
import { default as Logo } from 'assets/img/LogoMoneyLink.svg';
import { useTranslation } from 'react-i18next';
import ScrollToTopLink from 'utils/ScrollToTopLink';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterBox>
      <Container>
        <BoxRow>
          <InfoBox className='first'>
            <img src={Logo} alt='logo' />
            <InfoDescription>{t('footer.description')}</InfoDescription>
          </InfoBox>
          <InfoBox>
            <h4> {t('footer.titleOne')}</h4>
            <ul>
              <li>
                <ScrollToTopLink to='/'>{t('footer.menuOne')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/business'>{t('footer.menuTwo')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/investor'>{t('footer.menuThree')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/agent'>{t('footer.menuFour')}</ScrollToTopLink>
              </li>
            </ul>
          </InfoBox>
          <InfoBox>
            <h4> {t('footer.titleTwo')}</h4>
            <ul>
              {/* <li>{t('footer.menuFive')}</li>
              <li>{t('footer.menuSix')}</li> */}
              <li>
                <ScrollToTopLink to='/contact'>{t('footer.menuSeven')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/data_agreement'>{t('footer.menuEight')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/user_agreement'>{t('footer.menuNine')}</ScrollToTopLink>
              </li>
            </ul>
          </InfoBox>
        </BoxRow>
      </Container>
      <Line />
      <Container>
        <MiniFooter>
          <Copyright> {t('footer.copyright')}</Copyright>
          <NetworkBox>
            {/* <IconFacebook /> */}
            <IconInstagram />
            {/* <IconTwitter /> */}
            <IconYoutube />
            <IconTelegram />
          </NetworkBox>
        </MiniFooter>
      </Container>
    </FooterBox>
  );
};
