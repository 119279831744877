import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const WorkingMechanismBox = styled.div`
  display: inline-flex;
  padding: 80px 60px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 14px;
  background: ${colors.CHARCOAL};
  margin-top: 100px;
  width: 100%;
  @media (max-width: 510px) {
    padding: 60px 20px;
    margin-top: 80px;
  }
`;
const MechanismBoxText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  font-weight: 700;
  max-width: 652px;
`;

const MechanismTag = styled.p`
  color: ${colors.GOLDEN};
  font-size: 20px;
  text-transform: uppercase;
  @media (max-width: 510px) {
    font-size: 14px;
  }
`;
const MechanismTitle = styled.h3`
  color: ${colors.WHITE};
  font-size: 44px;
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 28px;
  }
`;
const MechanismListCard = styled.div`
  display: flex;
  align-items: stretch;
  gap: 26px;
  @media (max-width: 1090px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const MechanismCard = styled.div`
  display: flex;
  padding: 32px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
`;

const MechanismCardNum = styled.p`
  color: ${colors.GOLDEN};
  font-size: 64px;
  font-weight: 700;
  flex-shrink: 0;
`;

const MechanismCardTitle = styled.p`
  color: ${colors.WHITE};
  font-size: 24px;
  font-weight: 600;
  flex-shrink: 0;
  min-height: 60px;
  @media (max-width: 1090px) {
    min-height: 0px;
  }
`;

const MechanismCardDescription = styled.p`
  color: ${colors.GRAY};
  font-size: 20px;
  font-weight: 400;
  flex-grow: 1;
`;

export {
  WorkingMechanismBox,
  MechanismBoxText,
  MechanismTag,
  MechanismTitle,
  MechanismListCard,
  MechanismCard,
  MechanismCardNum,
  MechanismCardTitle,
  MechanismCardDescription,
};
