import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxExplanation = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: normal;
    gap: 40px;
    @media (max-width: 510px) {
      gap: 32px;
    }
  }
`;

const BoxExplanationMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ExplanationTag = styled.p`
  color: ${colors.TEAL};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 510px) {
    font-size: 16px;
  }
`;

const ExplanationTitle = styled.h3`
  color: ${colors.CHARCOAL};
  font-size: 44px;
  font-weight: 700;
  max-width: 470px;
  @media (max-width: 1100px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 28px;
  }
`;

const ExplanationDescription = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 20px;
  font-weight: 400;
  max-width: 526px;
  @media (max-width: 1100px) {
    max-width: 100%;
  }
`;

export {
  BoxExplanation,
  BoxExplanationMain,
  ExplanationTag,
  ExplanationTitle,
  ExplanationDescription,
};
