const WHITE = '#FFFFFF';
const TEAL = '#01AEB7';
const TEAL_HOVER = '#19D3DD';
const GOLDEN = '#F8B801';
const CHARCOAL = '#292D32';
const GRAY = '#8F91A0';
const LIGHT_GRAY = '#F3F4F6';
const BORDER_GREY = '#e2e2e2';
const SOFT_GRAY = '#eaeaea';
const INPUT_BORDER = '#DCDFE5';
const WARNING = '#FB4753';

const colors = {
  WHITE,
  TEAL,
  TEAL_HOVER,
  GOLDEN,
  CHARCOAL,
  GRAY,
  LIGHT_GRAY,
  BORDER_GREY,
  SOFT_GRAY,
  INPUT_BORDER,
  WARNING,
};

export default colors;
