import { useNavigate } from 'react-router-dom';

export const useNavigateToForm = () => {
  const navigate = useNavigate();

  const navigateToForm = () => {
    navigate('/contact#form');
  };

  return navigateToForm;
};
