import Container from 'index.style';
import React from 'react';
import {
  AdvantagesBlockBox,
  AdvantagesBoxText,
  AdvantagesBoxTag,
  AdvantagesBoxTitle,
  AdvantagesBoxDescription,
  AdvantagesListCard,
  AdvantagesCard,
  AdvantagesCardIcon,
  AdvantagesCardTitle,
  AdvantagesCardDescription,
} from './AdvantagesBlock.style';

interface AdvantageCard {
  title: string;
  description: string;
  icon: string;
}

interface AdvantagesBlockProps {
  tag: string;
  title: string;
  description: string;
  advantages: AdvantageCard[];
}

export const AdvantagesBlock: React.FC<AdvantagesBlockProps> = ({
  tag,
  title,
  description,
  advantages,
}) => {
  return (
    <Container>
      <AdvantagesBlockBox>
        <AdvantagesBoxText>
          <AdvantagesBoxTag>{tag}</AdvantagesBoxTag>
          <AdvantagesBoxTitle>{title}</AdvantagesBoxTitle>
          <AdvantagesBoxDescription>{description}</AdvantagesBoxDescription>
        </AdvantagesBoxText>
        <AdvantagesListCard>
          {advantages.map((advantage, index) => (
            <AdvantagesCard key={index}>
              <AdvantagesCardIcon src={advantage.icon} alt='icon' />
              <AdvantagesCardTitle>{advantage.title}</AdvantagesCardTitle>
              <AdvantagesCardDescription>{advantage.description}</AdvantagesCardDescription>
            </AdvantagesCard>
          ))}
        </AdvantagesListCard>
      </AdvantagesBlockBox>
    </Container>
  );
};
