import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  position: relative;
  z-index: 2;
  margin-bottom: 88px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 28px;
  max-width: 49.667vw;
  color: ${colors.CHARCOAL};

  @media (max-width: 900px) {
    padding-bottom: 0;
    max-width: 664px;
    top: 18%;
  }
  @media (max-width: 510px) {
    top: 20%;
    max-width: 364px;
  }
  h3 {
    font-size: 3.854vw;
    font-weight: 700;
    @media (max-width: 900px) {
      font-size: 54px;
    }
    @media (max-width: 700px) {
      font-size: 40px;
    }
  }
  span {
    color: ${colors.TEAL};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const LineBanner = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const Paragraph = styled.p`
  margin-top: 10px;
`;

const PrivacyPolicyLink = styled.a`
  color: #01aeb7;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;

  &:hover {
    color: #19d3dd;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

export { BoxMain, TextBox, LineBanner, Title, Paragraph, PrivacyPolicyLink, BoxContent };
