import { styled } from 'styled-components';

const InputRadioBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const InputRadioBoxTitle = styled.p`
  color: #292d32;
  font-size: 14px;
  font-weight: 600;
`;
const InputRadioBoxSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
const InputRadioSelect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export { InputRadioBox, InputRadioBoxTitle, InputRadioBoxSelect, InputRadioSelect };
