import styled from 'styled-components';
import colors from 'global_styling/colors';

const CalculatorConditionsBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 80px;
  padding: 80px 60px;
  margin: 140px 0px;
  border-radius: 14px;
  background: linear-gradient(90deg, ${colors.WHITE} 0%, rgba(255, 255, 255, 0) 100%);
  @media (max-width: 1200px) {
    flex-direction: column;
    background: linear-gradient(181deg, ${colors.WHITE} 0%, rgba(255, 255, 255, 0) 100%);
    margin: 60px 0px;
  }
  @media (max-width: 790px) {
    padding: 80px 20px;
    margin: 80px 0px;
  }
`;

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 28px;
`;

const BoxCalc = styled.div`
  display: flex;
  gap: 28px;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

const BoxCalculate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 310px;
`;

const BoxCalculateText = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 12px;
`;

const CalculateTag = styled.p`
  color: ${colors.GOLDEN};
  font-size: 20px;
  text-transform: uppercase;
`;

const CalculateHeader = styled.h3`
  color: ${colors.CHARCOAL};
  font-size: 44px;
`;

const CalculateInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export {
  CalculatorConditionsBox,
  BoxCalculate,
  BoxCalculateText,
  CalculateTag,
  CalculateHeader,
  CalculateInputBox,
  Box,
  BoxCalc,
};
