import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface InputProps {
  $hasError?: boolean;
}

const Input = styled.input<InputProps>`
  width: 100%;
  padding: 16px 24px;
  border-radius: 50px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.INPUT_BORDER};
  color: ${colors.CHARCOAL};
  font-size: 16px;
  font-weight: 400;

  ${({ $hasError: hasError }) =>
    hasError
      ? css`
          border: 1px solid ${colors.WARNING};
        `
      : ''}
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export { InputBox, Input, ErrorMessage };
