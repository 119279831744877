import { UserAgreementBlock } from 'components/UserAgreementBlock';

const UserAgreementScreen = () => {
  return (
    <>
      <UserAgreementBlock />
    </>
  );
};

export default UserAgreementScreen;
