import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import ruTranslations from './locales/ru/translation.json';
import kkTranslations from './locales/kk/translation.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    ru: {
      translation: ruTranslations,
    },
    kk: {
      translation: kkTranslations,
    },
  },
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['cookie', 'localStorage'],
    caches: ['cookie'],
  },
});

export default i18n;
