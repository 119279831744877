import { MainBanner } from 'components/MainBanner';
import Banner from 'assets/img/LandingMainBanner.webp';
import TabletBanner from 'assets/img/LandingMainTablet.webp';
import PhoneBanner from 'assets/img/LandingMainPhone.webp';
import { useTranslation } from 'react-i18next';
import { BusinessBlock } from 'components/BusinessBlock';
import { InvestorBlock } from 'components/InvestorBlock';
import { InfoBlock } from 'components/InfoBlock';
import { FeedbackBlock } from 'components/FeedbackBlock';
import { CheckContract } from 'components/CheckContract';

const LandingScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainBanner
        bannerImg={Banner}
        bannerTablet={TabletBanner}
        bannerPhone={PhoneBanner}
        title={[t('mainLandingBanner.title'), t('mainLandingBanner.titleTwo')]}
        description={t('mainLandingBanner.description')}
        button={t('mainLandingBanner.button')}
      />
      <BusinessBlock />
      <InvestorBlock />
      <CheckContract />
      <InfoBlock
        tag={t('infoBlock.tag')}
        title={t('infoBlock.title')}
        description={t('infoBlock.description')}
        button={t('infoBlock.button')}
      />
      <FeedbackBlock />
    </>
  );
};

export default LandingScreen;
