import {
  InputRadioBox,
  InputRadioBoxTitle,
  InputRadioBoxSelect,
  InputRadioSelect,
} from './InputRadio.style';
import Done from 'assets/img/RadioDone.webp';
import NotDone from 'assets/img/RadioNot.webp';

interface InputRadioProps {
  title: string;
  oneСhoice: string;
  twoСhoice: string;
  selectedChoice: string;
  onChoiceChange: (choice: string) => void;
}

export const InputRadio = ({
  title,
  oneСhoice,
  twoСhoice,
  selectedChoice,
  onChoiceChange,
}: InputRadioProps) => {
  return (
    <InputRadioBox>
      <InputRadioBoxTitle>{title}</InputRadioBoxTitle>
      <InputRadioBoxSelect>
        <InputRadioSelect onClick={() => onChoiceChange(oneСhoice)}>
          <img src={selectedChoice === oneСhoice ? Done : NotDone} alt='selection' />
          <p>{oneСhoice}</p>
        </InputRadioSelect>
        <InputRadioSelect onClick={() => onChoiceChange(twoСhoice)}>
          <img src={selectedChoice === twoСhoice ? Done : NotDone} alt='selection' />
          <p>{twoСhoice}</p>
        </InputRadioSelect>
      </InputRadioBoxSelect>
    </InputRadioBox>
  );
};
