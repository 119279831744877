import Container from 'index.style';
import {
  FaqBlockBox,
  FaqBoxText,
  FaqBoxTextTag,
  FaqBoxTextTitle,
  FaqBoxContent,
  QuestionRow,
  Box,
  AnswerBox,
} from './FaqBlock.style';
import React, { useState } from 'react';

type QuestionProps = {
  question: string;
  answer: string;
  $isOpen: boolean;
  onClick: () => void;
};

type QuestionType = {
  question: string;
  answer: string;
};

type FaqBlockProps = {
  tag: string;
  title: string;
  questions: QuestionType[];
};

const Question: React.FC<QuestionProps> = ({ question, answer, $isOpen, onClick }) => {
  const answerArray = answer.split(/\s*\d+\.\s*/).filter(Boolean);

  return (
    <Box onClick={onClick}>
      <QuestionRow $isOpen={$isOpen}>
        <p>{question}</p>
        <span>{$isOpen ? '–' : '+'}</span>
      </QuestionRow>
      {$isOpen && (
        <AnswerBox>
          {answerArray.map((text, index) => (
            <p key={index}>
              {index + 1}. {text}
            </p>
          ))}
        </AnswerBox>
      )}
    </Box>
  );
};

export const FaqBlock = ({ tag, title, questions }: FaqBlockProps) => {
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  const handleQuestionClick = (index: number) => {
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  return (
    <Container>
      <FaqBlockBox>
        <FaqBoxText>
          <FaqBoxTextTag>{tag}</FaqBoxTextTag>
          <FaqBoxTextTitle>{title}</FaqBoxTextTitle>
        </FaqBoxText>
        <FaqBoxContent>
          {questions.map((q, index) => (
            <Question
              key={index}
              question={q.question}
              answer={q.answer}
              $isOpen={openQuestion === index}
              onClick={() => handleQuestionClick(index)}
            />
          ))}
        </FaqBoxContent>
      </FaqBlockBox>
    </Container>
  );
};
