import React, { useState } from 'react';
import { Container, Input, SliderWrapper, Slider, SliderFill, BoxValue } from './InputSlider.style';
import { useTranslation } from 'react-i18next';

interface InputSliderProps {
  minValue: number;
  maxValue: number;
  startValue: number;
  title: string;
  oneStep: string;
  twoStep: string;
  threeStep: string;
  type: 'money' | 'months' | 'percent' | 'deal' | 'day';
  onValueChange: (newValue: number) => void; // Add this line
}

export const InputSlider: React.FC<InputSliderProps> = ({
  minValue,
  maxValue,
  startValue = minValue,
  title,
  oneStep,
  twoStep,
  threeStep,
  type,
  onValueChange,
}) => {
  const { t } = useTranslation();

  const formatValue = (
    value: number,
    type: 'money' | 'months' | 'percent' | 'deal' | 'day',
  ): string => {
    switch (type) {
      case 'money':
        return `${value.toLocaleString('ru-RU')} ₸`;
      case 'months':
        return `${value} ${getMonthText(value)}`;
      case 'day':
        return `${value} ${getDayText(value)}`;
      case 'percent':
        return `${value}%`;
      case 'deal':
        return `${value} ${getDealText(value)}`;
      default:
        return value.toString();
    }
  };

  const getMonthText = (num: number): string => {
    if (num === 1) return t('typeMonth.two');
    if (num > 1 && num < 5) return t('typeMonth.three');
    return t('typeMonth.four');
  };

  const getDayText = (num: number): string => {
    if (num === 1) return t('typeDay.one');
    if (num > 1 && num < 5) return t('typeDay.two');
    return t('typeDay.three');
  };

  const getDealText = (num: number): string => {
    if (num === 1) return 'сделка';
    if (num > 1 && num < 5) return 'сделки';
    return 'сделок';
  };

  const [value, setValue] = useState<number>(startValue);
  const [inputValue, setInputValue] = useState<string>(formatValue(startValue, type));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    }
  };

  const handleInputBlur = () => {
    const numericValue = parseInt(inputValue.replace(/[^\d]/g, ''), 10);

    if (isNaN(numericValue) || numericValue < minValue) {
      setValue(minValue);
      setInputValue(formatValue(minValue, type));
      onValueChange(minValue);
    } else if (numericValue > maxValue) {
      setValue(maxValue);
      setInputValue(formatValue(maxValue, type));
      onValueChange(maxValue);
    } else {
      setValue(numericValue);
      setInputValue(formatValue(numericValue, type));
      onValueChange(numericValue);
    }
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = parseInt(e.target.value, 10);
    setValue(numValue);
    setInputValue(formatValue(numValue, type));
    onValueChange(numValue);
  };

  const sliderPercentage = ((value - minValue) / (maxValue - minValue)) * 100;

  return (
    <Container>
      <h3>{title}</h3>
      <Input
        type='text'
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
      />
      <SliderWrapper>
        <SliderFill style={{ width: `${sliderPercentage}%` }} />
        <Slider
          type='range'
          value={value.toString()}
          onChange={handleRangeChange}
          min={minValue.toString()}
          max={maxValue.toString()}
        />
      </SliderWrapper>
      <BoxValue>
        <p>{oneStep}</p>
        <p>{twoStep}</p>
        <p>{threeStep}</p>
      </BoxValue>
    </Container>
  );
};
