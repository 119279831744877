import Container from 'index.style';
import React, { useEffect, useState } from 'react';
import {
  CalculatorConditionsBox,
  BoxCalculate,
  BoxCalculateText,
  CalculateTag,
  CalculateHeader,
  CalculateInputBox,
  Box,
  BoxCalc,
} from './CalculatorConditions.style';
import { InputSlider } from 'components/common/Input/InputSlider';
import { CalculatorCart } from 'components/CalculatorCart';
import { Select } from 'components/common/Input/Select';
import { useTranslation } from 'react-i18next';

export const CalculatorConditions = () => {
  const [financingValue, setFinancingValue] = useState(20000);
  const [daysValue, setDaysValue] = useState(3);
  const [selectedProcuring, setSelectedProcuring] = useState<string | null>('loan');
  const [selectedSchedule, setSelectedSchedule] = useState<string | null>('equal_parts');
  const [totalReward, setTotalReward] = useState<number | null>(null);
  const [monthlyPayment, setMonthlyPayment] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    let totalRewardCalc: number | string = 0;
    let monthlyPaymentCalc: number | string = 0;

    if (selectedProcuring === 'loan') {
      if (selectedSchedule === 'equal_parts') {
        totalRewardCalc = ((60 / 365) * daysValue * financingValue) / 100;
        monthlyPaymentCalc = (totalRewardCalc + financingValue) / Math.ceil(daysValue / 30);
      } else if (selectedSchedule === 'monthly_interest') {
        totalRewardCalc = ((55 / 365) * daysValue * financingValue) / 100;
        monthlyPaymentCalc = (totalRewardCalc * 30) / 100;
      }
    } else if (selectedProcuring === 'trade') {
      if (selectedSchedule === 'equal_parts') {
        totalRewardCalc = ((50 / 365) * daysValue * financingValue) / 100;
        monthlyPaymentCalc = (totalRewardCalc + financingValue) / Math.ceil(daysValue / 30);
      } else if (selectedSchedule === 'monthly_interest') {
        totalRewardCalc = ((55 / 365) * daysValue * financingValue) / 100;
        monthlyPaymentCalc = (totalRewardCalc * 30) / 100;
      }
    }
    if (selectedProcuring === 'factoring') {
      totalRewardCalc = ((55 / 365) * daysValue * financingValue) / 100;
      monthlyPaymentCalc = '-';
    }

    setTotalReward(typeof totalRewardCalc === 'number' ? totalRewardCalc : null);
    setMonthlyPayment(monthlyPaymentCalc.toString());
  }, [financingValue, daysValue, selectedProcuring, selectedSchedule]);

  const infoKeyValuePairs = [];

  if (selectedProcuring !== 'factoring') {
    infoKeyValuePairs.push({
      key: t('CalculatorConditions.monthlyPayment'),
      value: monthlyPayment
        ? parseFloat(monthlyPayment).toLocaleString('ru-RU', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })
        : '-',
    });
  }

  if (selectedSchedule === 'monthly_interest') {
    infoKeyValuePairs.push({
      key: t('CalculatorConditions.financingValue'),
      value:
        financingValue.toLocaleString('ru-RU', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }) + '₸',
    });
  }

  return (
    <Container>
      <CalculatorConditionsBox>
        <Box>
          <BoxCalculateText>
            <CalculateTag>{t('CalculatorConditions.tag')}</CalculateTag>
            <CalculateHeader>{t('CalculatorConditions.title')}</CalculateHeader>
          </BoxCalculateText>
          <BoxCalc>
            <BoxCalculate>
              <CalculateInputBox>
                <InputSlider
                  title={t('CalculatorConditions.paramOne')}
                  minValue={20000}
                  maxValue={10000000}
                  startValue={20000}
                  oneStep={`20 ${t('typeMoney.one')}`}
                  twoStep='5 млн'
                  threeStep='10 млн'
                  type='money'
                  onValueChange={setFinancingValue}
                />
              </CalculateInputBox>
              <Select
                title={t('CalculatorConditions.paramThree')}
                options={[
                  { value: 'loan', label: t('CalculatorConditions.loan') },
                  { value: 'trade', label: t('CalculatorConditions.trade') },
                  { value: 'factoring', label: t('CalculatorConditions.factoring') },
                ]}
                onOptionChange={setSelectedProcuring}
                selectedValue={selectedProcuring}
              />
            </BoxCalculate>
            <BoxCalculate>
              <CalculateInputBox>
                <InputSlider
                  title={t('CalculatorConditions.paramTwo')}
                  minValue={3}
                  maxValue={180}
                  startValue={3}
                  oneStep={`3 ${t('typeDay.two')}`}
                  twoStep={`90 ${t('typeDay.three')}`}
                  threeStep={`180 ${t('typeDay.three')}`}
                  type='day'
                  onValueChange={setDaysValue}
                />
              </CalculateInputBox>
              {selectedProcuring !== 'factoring' && (
                <Select
                  title={t('CalculatorConditions.paramFour')}
                  options={[
                    { value: 'equal_parts', label: t('CalculatorConditions.equal_parts') },
                    {
                      value: 'monthly_interest',
                      label: t('CalculatorConditions.monthly_interest'),
                    },
                  ]}
                  onOptionChange={setSelectedSchedule}
                  selectedValue={selectedSchedule}
                />
              )}
            </BoxCalculate>
          </BoxCalc>
        </Box>
        <CalculatorCart
          title={t('CalculatorConditions.rewardSum')}
          sum={`${totalReward?.toLocaleString('ru-RU', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          })}₸`}
          infoKeyValuePairs={infoKeyValuePairs}
        />
      </CalculatorConditionsBox>
    </Container>
  );
};
