import Container from 'index.style';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  CalculatorConditionsBox,
  BoxCalculate,
  BoxCalculateText,
  CalculateTag,
  CalculateHeader,
  CalculateInputBox,
  Box,
  BoxCalc,
} from './CalculatorIncome.style';
import { InputSlider } from 'components/common/Input/InputSlider';
import { CalculatorCart } from 'components/CalculatorCart';
import { Select } from 'components/common/Input/Select';
import { InputRadio } from 'components/common/Input/RadioCheck';
import { useTranslation } from 'react-i18next';

const HIGH_RISK_RATE_MAP: {
  [key: string]: number;
} = {
  loanEqualParts: 50,
  loanMonthlyInterest: 45,
  tradeEqualParts: 40,
  tradeMonthlyInterest: 45,
  factoring: 45,
};

const LOW_RISK_MULTIPLIER = 0.02;

export const CalculatorIncome: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [sumInvestment, setSumInvestment] = useState(20000);
  const [investmentTerm, setInvestmentTerm] = useState(1);
  const [selectedInstrument, setSelectedInstrument] = useState<
    'loan' | 'trade' | 'factoring' | null
  >('loan');
  const [riskHigh, setRiskHigh] = useState(t('CalculatorIncome.tall'));
  const [rewardDistribution, setRewardDistribution] = useState<
    'equal_parts' | 'monthly_interest' | null
  >('equal_parts');

  useEffect(() => {
    setRiskHigh(t('CalculatorIncome.tall'));
  }, [t, i18n.language]);

  const highRiskOptions = [
    { value: 'loan', label: t('CalculatorIncome.loan') },
    { value: 'trade', label: t('CalculatorIncome.trade') },
    { value: 'factoring', label: t('CalculatorIncome.factoring') },
  ];

  const lowRiskOptions = [{ value: 'loan', label: t('CalculatorIncome.loan') }];

  const calculateReward = (
    sumInvestment: number,
    investmentTerm: number,
    riskHigh: string,
    selectedInstrument: 'loan' | 'trade' | 'factoring' | null,
    rewardDistribution: 'equal_parts' | 'monthly_interest' | null,
  ) => {
    if (riskHigh === t('CalculatorIncome.low')) {
      return sumInvestment * investmentTerm * LOW_RISK_MULTIPLIER;
    }
    if (riskHigh === t('CalculatorIncome.tall')) {
      if (selectedInstrument === 'factoring') {
        return ((HIGH_RISK_RATE_MAP['factoring'] ?? 0) / 365) * investmentTerm * sumInvestment;
      }

      if (selectedInstrument && rewardDistribution) {
        const key = `${selectedInstrument}${
          rewardDistribution === 'equal_parts' ? 'EqualParts' : 'MonthlyInterest'
        }`;
        return ((HIGH_RISK_RATE_MAP[key] ?? 0) / 365) * investmentTerm * sumInvestment;
      }
    }

    return 0;
  };

  const calculatedReward = useMemo(() => {
    return calculateReward(
      sumInvestment,
      investmentTerm,
      riskHigh,
      selectedInstrument as 'loan' | 'trade' | 'factoring' | null,
      rewardDistribution as 'equal_parts' | 'monthly_interest' | null,
    );
  }, [sumInvestment, investmentTerm, riskHigh, selectedInstrument, rewardDistribution]);

  const handleRiskChange = useCallback((newRisk: string) => {
    setRiskHigh(newRisk);
    if (newRisk === t('CalculatorIncome.low')) {
      setSelectedInstrument('loan');
    }
  }, []);

  const rewardDistributionOptions = useMemo(
    () => [
      { value: 'equal_parts', label: t('CalculatorIncome.equal_parts') },
      { value: 'monthly_interest', label: t('CalculatorIncome.monthly_interest') },
    ],
    [t],
  );

  const showRewardDistribution = useMemo(() => {
    return riskHigh === t('CalculatorIncome.tall') && selectedInstrument !== 'factoring';
  }, [riskHigh, selectedInstrument]);

  const instrumentOptions = useMemo(() => {
    return riskHigh === t('CalculatorIncome.tall') ? highRiskOptions : lowRiskOptions;
  }, [riskHigh]);

  return (
    <Container>
      <CalculatorConditionsBox>
        <Box>
          <BoxCalculateText>
            <CalculateTag>{t('CalculatorIncome.tag')}</CalculateTag>
            <CalculateHeader>{t('CalculatorIncome.title')}</CalculateHeader>
          </BoxCalculateText>
          <BoxCalc>
            <BoxCalculate>
              <CalculateInputBox>
                <InputSlider
                  title={t('CalculatorIncome.paramOne')}
                  minValue={20000}
                  maxValue={10000000}
                  startValue={20000}
                  oneStep={`20 ${t('typeMoney.one')}`}
                  twoStep='5 млн.'
                  threeStep='10 млн.'
                  type='money'
                  onValueChange={setSumInvestment}
                />
              </CalculateInputBox>
              <InputRadio
                title={t('CalculatorIncome.paramThree')}
                oneСhoice={t('CalculatorIncome.tall')}
                twoСhoice={t('CalculatorIncome.low')}
                selectedChoice={riskHigh}
                onChoiceChange={handleRiskChange}
              />
            </BoxCalculate>
            <BoxCalculate>
              <CalculateInputBox>
                <InputSlider
                  title={t('CalculatorIncome.paramTwo')}
                  minValue={1}
                  maxValue={6}
                  startValue={1}
                  oneStep={`1 ${t('typeMonth.one')}`}
                  twoStep={`3 ${t('typeMonth.one')}`}
                  threeStep={`6 ${t('typeMonth.one')}`}
                  type='months'
                  onValueChange={setInvestmentTerm}
                />
              </CalculateInputBox>
              <Select
                title={t('CalculatorIncome.paramFour')}
                options={instrumentOptions}
                onOptionChange={(selected) =>
                  setSelectedInstrument(selected as 'loan' | 'trade' | 'factoring' | null)
                }
                selectedValue={selectedInstrument}
              />
            </BoxCalculate>
          </BoxCalc>
          {showRewardDistribution && (
            <Select
              title={t('CalculatorIncome.paramFive')}
              options={rewardDistributionOptions}
              onOptionChange={(selected) =>
                setRewardDistribution(selected as 'equal_parts' | 'monthly_interest' | null)
              }
              selectedValue={rewardDistribution}
            />
          )}
        </Box>
        <CalculatorCart
          title={t('CalculatorIncome.rewardSum')}
          sum={`${parseFloat(calculatedReward.toFixed(1)).toLocaleString('ru-RU')}₸`}
          infoKeyValuePairs={[{ key: t('CalculatorIncome.risk'), value: riskHigh }]}
        />
      </CalculatorConditionsBox>
    </Container>
  );
};
