import { useTranslation } from 'react-i18next';
import {
  BoxMain,
  TextBox,
  ListCardContact,
  CardContact,
  CardContactTitle,
  CardContactDescription,
  CardContactBoxText,
  Icon,
  LineBanner,
} from './ContactBlock.style';
import Container from 'index.style';
import IconLocation from 'assets/img/IconLocation.webp';
import IconPhone from 'assets/img/IconPhone.webp';
import IconMail from 'assets/img/IconMail.webp';
import LineBaner from 'assets/img/LineBanner.webp';

export const ContactBlock = () => {
  const { t } = useTranslation();

  return (
    <>
      <LineBanner src={LineBaner} alt='line' />
      <Container>
        <BoxMain>
          <TextBox>
            <span>{t('contactBlock.title')}</span>
            <h3>{t('contactBlock.description')}</h3>
          </TextBox>
          <ListCardContact>
            <CardContact>
              <Icon src={IconLocation} alt='icon' />
              <div>
                <CardContactTitle>{t('contactBlock.cardOneTitle')}</CardContactTitle>
                <CardContactDescription>{t('contactBlock.cardOneContact')}</CardContactDescription>
              </div>
            </CardContact>
            <CardContact>
              <Icon src={IconPhone} alt='icon' />
              <div>
                <CardContactTitle>{t('contactBlock.cardTwoTitle')}</CardContactTitle>
                <CardContactBoxText>
                  <p>{t('contactBlock.cardTwoContact')}</p>
                  <span>{t('contactBlock.cardTwoContactInfo')}</span>
                </CardContactBoxText>
                {/* <CardContactBoxText>
                  <p>{t('contactBlock.cardTwoContactSecond')}</p>
                  <span>{t('contactBlock.cardTwoContactInfoSecond')}</span>
                </CardContactBoxText> */}
              </div>
            </CardContact>
            <CardContact>
              <Icon src={IconMail} alt='icon' />
              <div>
                <CardContactTitle>{t('contactBlock.cardThreeTitle')}</CardContactTitle>
                <CardContactBoxText>
                  <p>
                    <a href={`mailto:${t('contactBlock.cardThreeContactSecond')}`}>
                      {t('contactBlock.cardThreeContact')}
                    </a>
                  </p>{' '}
                  <span>{t('contactBlock.cardThreeContactInfo')}</span>
                </CardContactBoxText>
                {/* <CardContactBoxText>
                  <p>
                    <a href={`mailto:${t('contactBlock.cardThreeContactSecond')}`}>
                      {t('contactBlock.cardThreeContactSecond')}
                    </a>
                  </p>
                  <span>{t('contactBlock.cardThreeContactInfoSecond')}</span>
                </CardContactBoxText> */}
              </div>
            </CardContact>
          </ListCardContact>
        </BoxMain>
      </Container>
    </>
  );
};
