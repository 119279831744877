import React from 'react';
import { useNavigateToForm } from 'utils/useNavigateToForm';
import {
  CartBox,
  CartBoxHeader,
  CartHeaderTitle,
  CartHeaderSum,
  CartBoxInfo,
  CartInfoKeyValue,
  CartInfoKey,
  CartInfoValue,
} from './CalculatorCart.style';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';

interface InfoKeyValue {
  key: string;
  value: string;
}

interface CalculatorCartProps {
  title: string;
  sum: string;
  infoKeyValuePairs: InfoKeyValue[];
}

export const CalculatorCart: React.FC<CalculatorCartProps> = ({
  title,
  sum,
  infoKeyValuePairs,
}) => {
  const navigateToForm = useNavigateToForm();
  const { t } = useTranslation();

  return (
    <CartBox>
      <CartBoxHeader>
        <CartHeaderTitle>{title}</CartHeaderTitle>
        <CartHeaderSum>{sum}</CartHeaderSum>
      </CartBoxHeader>
      <CartBoxInfo>
        {infoKeyValuePairs.map((pair, index) => (
          <CartInfoKeyValue key={index}>
            <CartInfoKey>{pair.key}</CartInfoKey>
            <CartInfoValue>{pair.value}</CartInfoValue>
          </CartInfoKeyValue>
        ))}
        <Button text={t('CalculatorIncome.feedback')} className='yellow' onClick={navigateToForm} />
      </CartBoxInfo>
    </CartBox>
  );
};
