import { useEffect, useState } from 'react';
import {
  CalculatorConditionsBox,
  BoxCalculate,
  BoxCalculateText,
  CalculateTag,
  CalculateHeader,
  CalculateInputBox,
  Box,
  BoxCalc,
} from './CalculatorReward.style';
import { InputSlider } from 'components/common/Input/InputSlider';
import { CalculatorCart } from 'components/CalculatorCart';
import Container from 'index.style';
import { useTranslation } from 'react-i18next';

// Constants for calculation
const FIRST_TIER_LIMIT = 50000000;
const SECOND_TIER_LIMIT = 100000000;
const FIRST_TIER_RATE = 0.005;
const SECOND_TIER_RATE = 0.006;
const THIRD_TIER_RATE = 0.007;

/**
 * Calculate the reward based on the sum of deals
 * @param sumAmountDeal - Sum of attracted deals
 * @returns Calculated reward
 */
const calculateReward = (sumAmountDeal: number): number => {
  if (sumAmountDeal < FIRST_TIER_LIMIT) {
    return sumAmountDeal * FIRST_TIER_RATE;
  }
  if (sumAmountDeal < SECOND_TIER_LIMIT) {
    return sumAmountDeal * SECOND_TIER_RATE;
  }
  return sumAmountDeal * THIRD_TIER_RATE;
};

export const CalculatorReward = () => {
  const [sumAmountDeal, setSumAmountDeal] = useState(100000);
  const [reward, setReward] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const calculatedReward = calculateReward(sumAmountDeal);
    setReward(calculatedReward);
  }, [sumAmountDeal]);

  return (
    <Container>
      <CalculatorConditionsBox>
        <Box>
          <BoxCalculateText>
            <CalculateTag>{t('CalculatorReward.tag')}</CalculateTag>
            <CalculateHeader>{t('CalculatorReward.title')}</CalculateHeader>
          </BoxCalculateText>
          <BoxCalc>
            <BoxCalculate>
              <CalculateInputBox>
                <InputSlider
                  title={t('CalculatorReward.paramOne')}
                  minValue={100000}
                  maxValue={20000000}
                  startValue={100000}
                  oneStep={`100 ${t('typeMoney.one')}`}
                  twoStep='100 млн.'
                  threeStep='200 млн.'
                  type='money'
                  onValueChange={setSumAmountDeal}
                />
              </CalculateInputBox>
            </BoxCalculate>
          </BoxCalc>
        </Box>
        <CalculatorCart
          title={t('CalculatorReward.rewardSum')}
          sum={`${parseFloat(reward.toFixed(1)).toLocaleString('ru-RU')}₸`}
          infoKeyValuePairs={[]}
        />
      </CalculatorConditionsBox>
    </Container>
  );
};
