import React, { useState, useEffect } from 'react';
import { Box, Title } from './CookiesAccept.style';
import { cookiesSet, cookiesNotSet, cookiesAccepted, cookiesDecline } from 'utils/cookiesKeys';
import { Button } from 'components/common/Button';

export const CookiesAccept = () => {
  const [cookiesShown, setCookiesShown] = useState(false);

  useEffect(() => {
    if (!document.cookie.includes(cookiesSet) && !document.cookie.includes(cookiesNotSet)) {
      setCookiesShown(true);
    } else if (document.cookie.includes(cookiesNotSet)) {
      setCookiesShown(true);
    }
  }, []);

  const handleAccept = () => {
    setCookiesShown(false);
    document.cookie = cookiesAccepted;
  };

  const handleDecline = () => {
    setCookiesShown(false);
    document.cookie = cookiesDecline;
  };

  return (
    <>
      {cookiesShown && (
        <Box>
          <Title>
            This website uses cookies for analytics and to improve provided services. By choosing I
            Accept, you consent to our use of them and other tracking technologies according to our
            privacy policy
          </Title>

          <Button text='Accept' className='yellow' onClick={handleAccept}></Button>
          <Button text='Decline' onClick={handleDecline}></Button>
        </Box>
      )}
    </>
  );
};
