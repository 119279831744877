import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  position: relative;
  z-index: 2;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 28px;
  max-width: 49.667vw;
  color: ${colors.CHARCOAL};

  @media (max-width: 900px) {
    padding-bottom: 0;
    max-width: 664px;
    top: 18%;
  }
  @media (max-width: 510px) {
    top: 20%;
    max-width: 364px;
  }
  h3 {
    font-size: 3.854vw;
    font-weight: 700;
    @media (max-width: 900px) {
      font-size: 54px;
    }
    @media (max-width: 700px) {
      font-size: 40px;
    }
  }
  span {
    color: ${colors.TEAL};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const ListCardContact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  margin-top: 74px;
  margin-bottom: 54px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CardContact = styled.div`
  width: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 14px;
  background: linear-gradient(360deg, ${colors.WHITE} 0%, ${colors.LIGHT_GRAY} 100%);

  @media (max-width: 900px) {
    flex-direction: row;
    min-height: 204px;
  }
  @media (max-width: 510px) {
    flex-direction: column;
    min-height: auto;
  }
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const CardContactTitle = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
  @media (max-width: 900px) {
    text-align: start;
  }
  @media (max-width: 510px) {
    text-align: center;
  }
`;

const CardContactDescription = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  @media (max-width: 900px) {
    text-align: start;
  }
  @media (max-width: 510px) {
    text-align: center;
  }
`;

const CardContactBoxText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 900px) {
    text-align: start;
  }
  @media (max-width: 510px) {
    text-align: center;
  }
  a {
    color: ${colors.TEAL};
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    &:hover {
      color: ${colors.TEAL_HOVER};
    }
  }
  span {
    color: ${colors.CHARCOAL};
    font-size: 14px;
    font-weight: 400;
  }
`;

const Icon = styled.img`
  width: 82px;
  height: 82px;
`;

const LineBanner = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
`;

export {
  BoxMain,
  TextBox,
  ListCardContact,
  CardContact,
  CardContactTitle,
  CardContactDescription,
  CardContactBoxText,
  Icon,
  LineBanner,
};
