import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MainBanner } from 'components/MainBanner';
import { ExplanationBlock } from 'components/ExplanationBlock';
import { WorkingMechanism } from 'components/WorkingMechanism';
import { AdvantagesBlock } from 'components/AdvantagesBlock';
import { CalculatorConditions } from 'components/CalculatorConditions';
import { FaqBlock } from 'components/FaqBlock';
import { InfoBlock } from 'components/InfoBlock';
import IconMoney from 'assets/img/IconMoneyGold.webp';
import IconCursor from 'assets/img/IconCursor.webp';
import IconCalendar from 'assets/img/IconCalendar.webp';
import IconPercent from 'assets/img/IconPercent.webp';
import Banner from 'assets/img/BusinessMainBanner.webp';
import TabletBanner from 'assets/img/BusinessMainTablet.webp';
import PhoneBanner from 'assets/img/BusinessMainPhone.webp';

const getFaqQuestions = (t: TFunction) => [
  {
    question: t('faqBlock.questionOne'),
    answer: t('faqBlock.answerOne'),
  },
  // {
  //   question: t('faqBlock.questionTwo'),
  //   answer: t('faqBlock.answerTwo'),
  // },
  // {
  //   question: t('faqBlock.questionThree'),
  //   answer: t('faqBlock.answerThree'),
  // },
  // {
  //   question: t('faqBlock.questionFour'),
  //   answer: t('faqBlock.answerFour'),
  // },
];

const getAdvantagesData = (t: TFunction) => [
  {
    title: t('advantagesBlock.cardOneTitle'),
    description: t('advantagesBlock.cardOneDescription'),
    icon: IconCursor,
  },
  {
    title: t('advantagesBlock.cardTwoTitle'),
    description: t('advantagesBlock.cardTwoDescription'),
    icon: IconMoney,
  },
  {
    title: t('advantagesBlock.cardThreeTitle'),
    description: t('advantagesBlock.cardThreeDescription'),
    icon: IconCalendar,
  },
  {
    title: t('advantagesBlock.cardFourTitle'),
    description: t('advantagesBlock.cardFourDescription'),
    icon: IconPercent,
  },
];

const getWorkingMechanismData = (t: TFunction) => [
  {
    title: t('workingMechanismBlock.cardOneTitle'),
    description: t('workingMechanismBlock.cardOneDescription'),
  },
  {
    title: t('workingMechanismBlock.cardTwoTitle'),
    description: t('workingMechanismBlock.cardTwoDescription'),
  },
  {
    title: t('workingMechanismBlock.cardThreeTitle'),
    description: t('workingMechanismBlock.cardThreeDescription'),
  },
];

const BusinessScreen = () => {
  const { t } = useTranslation();

  const questions = useMemo(() => getFaqQuestions(t), [t]);
  const advantagesData = useMemo(() => getAdvantagesData(t), [t]);
  const workingMechanismData = useMemo(() => getWorkingMechanismData(t), [t]);

  return (
    <>
      <MainBanner
        bannerImg={Banner}
        bannerTablet={TabletBanner}
        bannerPhone={PhoneBanner}
        title={t('mainBusinessBanner.title')}
        description={t('mainBusinessBanner.description')}
        button={t('mainBusinessBanner.button')}
      />
      <ExplanationBlock
        tag={t('explanationBlock.tag')}
        title={t('explanationBlock.title')}
        description={t('explanationBlock.description')}
      />
      <WorkingMechanism
        tag={t('workingMechanismBlock.tag')}
        header={t('workingMechanismBlock.header')}
        cards={workingMechanismData}
      />
      <AdvantagesBlock
        tag={t('advantagesBlock.tag')}
        title={t('advantagesBlock.title')}
        description={t('advantagesBlock.description')}
        advantages={advantagesData}
      />
      <CalculatorConditions />
      <FaqBlock tag={t('faqBlock.tag')} title={t('faqBlock.title')} questions={questions} />
      <InfoBlock
        tag={t('infoBlockBusiness.tag')}
        title={t('infoBlockBusiness.title')}
        description={t('infoBlockBusiness.description')}
        button={t('infoBlockBusiness.button')}
      />
    </>
  );
};

export default BusinessScreen;
