import React, { useState } from 'react';
import { BoxSelect } from './Select.style';
import { default as IconArrow } from 'assets/img/IconArrow.svg';

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  title: string;
  options: Option[];
  onOptionChange: (value: string) => void;
  selectedValue: string | null;
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  title,
  options,
  onOptionChange,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BoxSelect className={isOpen ? 'open' : ''}>
      <h3>{title}</h3>
      <div className='dropdown-header' onClick={() => setIsOpen(!isOpen)}>
        {options.find((option) => option.value === selectedValue)?.label || 'Выберите...'}
        <img src={IconArrow} alt='Arrow down' className={isOpen ? 'arrow-up' : 'arrow-down'} />
      </div>
      {isOpen && (
        <div className='options'>
          {options.map((option) => (
            <div
              key={option.value}
              className='option'
              onClick={() => {
                onOptionChange(option.value);
                setIsOpen(false);
              }}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </BoxSelect>
  );
};
