import { styled } from 'styled-components';
import colors from 'global_styling/colors';
import InputMask from 'react-input-mask';

const InfoBlockBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  margin-bottom: 180px;
  gap: 30px;
  img {
    position: absolute;
    z-index: -1;
    margin-top: -120px;
    width: 100%;
  }
`;

const InfoBoxText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  gap: 20px;
  align-items: center;
  p {
    color: ${colors.TEAL};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 510px) {
      font-size: 14px;
    }
  }
  h3 {
    color: ${colors.CHARCOAL};
    font-size: 44px;
    font-weight: 700;
    max-width: 1000px;
    text-align: center;
    margin-bottom: 10px;
    @media (max-width: 510px) {
      font-size: 28px;
    }
  }

  @media (max-width: 900px) {
    margin-top: 166px;
  }
  @media (max-width: 510px) {
    margin-top: 128px;
  }
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
  .input-error {
    border: 1px solid red;
  }
`;

const InputMaskCustomBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputMaskCustom = styled(InputMask)`
  width: 100%;
  padding: 16px 24px;
  border-radius: 50px;
  background: ${colors.WHITE};
  border: 1px solid ${colors.INPUT_BORDER};
  color: ${colors.CHARCOAL};
  font-size: 16px;
  font-weight: 400;
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
`;

export { InfoBlockBox, InfoBoxText, InputBox, InputMaskCustomBox, InputMaskCustom, ErrorMessage };
