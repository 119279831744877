import Container from 'index.style';
import React from 'react';
import {
  FeedbackBox,
  FeedbackBoxText,
  FeedbackTag,
  FeedbackTitle,
  FeedbackDescription,
} from './FeedbackBlock.style';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useNavigateToForm } from 'utils/useNavigateToForm';

export const FeedbackBlock = () => {
  const { t } = useTranslation();
  const navigateToForm = useNavigateToForm();

  return (
    <Container>
      <FeedbackBox>
        <FeedbackBoxText>
          <FeedbackTag>{t('feedbackBlock.tag')}</FeedbackTag>
          <FeedbackTitle>{t('feedbackBlock.title')}</FeedbackTitle>
          <FeedbackDescription>{t('feedbackBlock.description')}</FeedbackDescription>
          <Button onClick={navigateToForm} text={t('feedbackBlock.button')} className='yellow' />
        </FeedbackBoxText>
      </FeedbackBox>
    </Container>
  );
};
