import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MainBanner } from 'components/MainBanner';
import { ExplanationBlock } from 'components/ExplanationBlock';
import { WorkingMechanism } from 'components/WorkingMechanism';
import { AdvantagesBlock } from 'components/AdvantagesBlock';
import { FaqBlock } from 'components/FaqBlock';
import { InfoBlock } from 'components/InfoBlock';
import { CalculatorIncome } from 'components/CalculatorIncome';
import IconMoney from 'assets/img/IconMoneyGold.webp';
import IconCursor from 'assets/img/IconCursor.webp';
import IconCalendar from 'assets/img/IconCalendar.webp';
import IconPercent from 'assets/img/IconPercent.webp';
import Banner from 'assets/img/InvestorMainBanner.webp';
import TabletBanner from 'assets/img/InvestorMainTablet.webp';
import PhoneBanner from 'assets/img/InvestorMainPhone.webp';

const getFaqQuestions = (t: TFunction) => [
  {
    question: t('faqBlockInvestor.questionOne'),
    answer: t('faqBlockInvestor.answerOne'),
  },
  // {
  //   question: t('faqBlockInvestor.questionTwo'),
  //   answer: t('faqBlockInvestor.answerTwo'),
  // },
  // {
  //   question: t('faqBlockInvestor.questionThree'),
  //   answer: t('faqBlockInvestor.answerThree'),
  // },
  // {
  //   question: t('faqBlockInvestor.questionFour'),
  //   answer: t('faqBlockInvestor.answerFour'),
  // },
];

const getAdvantagesData = (t: TFunction) => [
  {
    title: t('advantagesBlockInvestor.cardOneTitle'),
    description: t('advantagesBlockInvestor.cardOneDescription'),
    icon: IconCursor,
  },
  {
    title: t('advantagesBlockInvestor.cardTwoTitle'),
    description: t('advantagesBlockInvestor.cardTwoDescription'),
    icon: IconMoney,
  },
  {
    title: t('advantagesBlockInvestor.cardThreeTitle'),
    description: t('advantagesBlockInvestor.cardThreeDescription'),
    icon: IconCalendar,
  },
  {
    title: t('advantagesBlockInvestor.cardFourTitle'),
    description: t('advantagesBlockInvestor.cardFourDescription'),
    icon: IconPercent,
  },
];

const getWorkingMechanismData = (t: TFunction) => [
  {
    title: t('workingMechanismBlockInvestor.cardOneTitle'),
    description: t('workingMechanismBlockInvestor.cardOneDescription'),
  },
  {
    title: t('workingMechanismBlockInvestor.cardTwoTitle'),
    description: t('workingMechanismBlockInvestor.cardTwoDescription'),
  },
  {
    title: t('workingMechanismBlockInvestor.cardThreeTitle'),
    description: t('workingMechanismBlockInvestor.cardThreeDescription'),
  },
];

const InvestorScreen = () => {
  const { t } = useTranslation();

  const questions = useMemo(() => getFaqQuestions(t), [t]);
  const advantagesData = useMemo(() => getAdvantagesData(t), [t]);
  const workingMechanismData = useMemo(() => getWorkingMechanismData(t), [t]);

  return (
    <>
      <MainBanner
        bannerImg={Banner}
        bannerTablet={TabletBanner}
        bannerPhone={PhoneBanner}
        title={t('mainInvestorBanner.title')}
        description={t('mainInvestorBanner.description')}
        button={t('mainInvestorBanner.button')}
      />
      <ExplanationBlock
        tag={t('explanationBlockInvestor.tag')}
        title={t('explanationBlockInvestor.title')}
        description={t('explanationBlockInvestor.description')}
      />
      <WorkingMechanism
        tag={t('workingMechanismBlockInvestor.tag')}
        header={t('workingMechanismBlockInvestor.header')}
        cards={workingMechanismData}
      />
      <AdvantagesBlock
        tag={t('advantagesBlockInvestor.tag')}
        title={t('advantagesBlockInvestor.title')}
        description={t('advantagesBlockInvestor.description')}
        advantages={advantagesData}
      />
      <CalculatorIncome />
      <FaqBlock
        tag={t('faqBlockInvestor.tag')}
        title={t('faqBlockInvestor.title')}
        questions={questions}
      />
      <InfoBlock
        tag={t('infoBlockInvestor.tag')}
        title={t('infoBlockInvestor.title')}
        description={t('infoBlockInvestor.description')}
        button={t('infoBlockInvestor.button')}
      />
    </>
  );
};

export default InvestorScreen;
