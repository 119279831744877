import styled from 'styled-components';

interface InputProps {
  $isError: boolean;
}

const Box = styled.div`
  display: flex;
  padding: 32px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  border-radius: 14px;
  background: var(--Background-White, #fff);
  margin: 140px 0px 140px 0px;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const SearchBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextInfo = styled.p`
  color: #01aeb7;
  font-size: 20px;
  font-weight: 600;
  text-transform: lowercase;
`;

const TextHeader = styled.h3`
  color: #292d32;
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
`;

const InputBox = styled.div<InputProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  input {
    border-radius: 6px;
    border: 1px solid ${({ $isError }) => ($isError ? '#FF7D7D' : 'var(--Control, #9aa4b0)')};
    background: #fff;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 10px;
    color: ${({ $isError }) => ($isError ? '#FF7D7D' : 'var(--Control, #9aa4b0)')};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    min-width: 302px;

    &:focus {
      outline: none;
      border: 1px solid ${({ $isError }) => ($isError ? '#FF7D7D' : '#01a5ad')};
      color: ${({ $isError }) => ($isError ? '#FF7D7D' : '#292d32')};
    }
  }
  button {
    display: flex;
    width: 223px;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 6px;
    background: #01aeb7;
    color: #fff;

    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      background: #07bec8;
    }
  }
`;

const InputContract = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  div {
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const BoxResponseContract = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 14px;
  background: #fff;
  max-width: 510px;
`;

const TitleResponseContract = styled.div`
  border-bottom: 1px solid #7d7d7d;
  display: flex;
  padding: 10px;
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
`;

const InfoResponseContract = styled.div`
  display: flex;
  padding: 6px;
  width: 100%;
  gap: 22px;
  align-items: center;
  color: #474747;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 600px) {
    padding: 4px;
    gap: 8px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
  }

  strong {
    color: #474747;
    font-size: 14px;
    font-weight: 600;
    min-width: 184px;

    @media (max-width: 600px) {
      min-width: 120px;
    }
  }
`;

const ButtonDowland = styled.button`
  display: flex;
  padding: 10px 43px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Primary, #01aeb7);
  background: var(--Primary, #01aeb7);
  box-shadow: 0px 5px 18px 0px rgba(40, 41, 61, 0.07);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #07bec8;
  }
`;

export {
  Box,
  SearchBox,
  HeaderBox,
  TextInfo,
  TextHeader,
  InputBox,
  InputContract,
  BoxResponseContract,
  TitleResponseContract,
  InfoResponseContract,
  ButtonDowland,
};
