import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { isValidPhone } from 'utils/isValidPhone';
import { Button } from 'components/common/Button';
import Container from 'index.style';
import LogoVector from 'assets/img/LogoVector.webp';
import {
  InfoBlockBox,
  InfoBoxText,
  InputBox,
  InputMaskCustomBox,
  InputMaskCustom,
  ErrorMessage,
} from './ContactForm.style';
import { InputText } from 'components/common/Input/InputText';
import { sendFormFeedback } from 'components/api/apiService';

export const ContactForm = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [inputErrors, setInputErrors] = useState({
    nameError: '',
    phoneError: '',
    messageError: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();

  const validateForm = (name: string, phone: string, message: string) => {
    const isNameValid = name !== '';
    const isMessageValid = message !== '';
    const isPhoneValid = isValidPhone(phone);

    const errors = {
      nameError: isNameValid ? '' : t('contactForm.errorName'),
      phoneError: isPhoneValid ? '' : t('contactForm.errorPhone'),
      messageError: isMessageValid ? '' : t('contactForm.errorText'),
    };

    return { isValid: isNameValid && isMessageValid && isPhoneValid, errors };
  };

  useEffect(() => {
    if (location.hash === '#form') {
      const element = document.getElementById('form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const formatPhone = (phone: string) => phone.replace(/\D/g, '');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);
  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMessage(event.target.value);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
    const isPhoneValid = isValidPhone(value);
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      phoneError: isPhoneValid ? '' : t('contactForm.errorPhone'),
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { isValid, errors } = validateForm(name, phone, message);
    setInputErrors(errors);

    if (isValid) {
      const formattedPhone = formatPhone(phone);
      const payload = {
        name,
        message,
        phone: formattedPhone,
      };

      try {
        const response = await sendFormFeedback(payload);
        if (response.status === 200) {
          setIsFormSubmitted(true);
        } else {
          console.log(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while sending the form:', error);
      }
    }
  };

  return (
    <Container>
      {isFormSubmitted ? (
        <InfoBlockBox>
          <img src={LogoVector} alt='logo-vector' loading='lazy' />
          <InfoBoxText id='form'>
            <p>{t('contactForm.responseTitle')}</p>
            <h3>{t('contactForm.responseDescription')}</h3>
          </InfoBoxText>
        </InfoBlockBox>
      ) : (
        <InfoBlockBox>
          <img src={LogoVector} alt='logo-vector' loading='lazy' />
          <InfoBoxText id='form'>
            <p>{t('contactForm.title')}</p>
            <h3>{t('contactForm.description')}</h3>
            <InputBox>
              <InputText
                type='text'
                value={name}
                placeholder={t('contactForm.inputName')}
                onChange={handleNameChange}
                error={inputErrors.nameError}
              />
              <InputMaskCustomBox>
                <InputMaskCustom
                  mask='+7 (999) 999-99-99'
                  maskChar={null}
                  value={phone}
                  placeholder={t('contactForm.inputPhone')}
                  onChange={handlePhoneChange}
                  className={inputErrors.phoneError ? 'input-error' : ''}
                />
                {inputErrors.phoneError && <ErrorMessage>{inputErrors.phoneError}</ErrorMessage>}
              </InputMaskCustomBox>
            </InputBox>
            <InputText
              type='text'
              value={message}
              placeholder={t('contactForm.inputMessage')}
              onChange={handleMessageChange}
              error={inputErrors.messageError}
            />
            <form onSubmit={handleFormSubmit}>
              <Button type='submit' text={t('contactForm.button')} className='yellow' />
            </form>{' '}
          </InfoBoxText>
        </InfoBlockBox>
      )}
    </Container>
  );
};
