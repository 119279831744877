import React from 'react';
import { BoxMain, ImgBanner, TextBox } from './MainBanner.style';
import { Button } from 'components/common/Button';
import { useMediaQuery } from 'react-responsive';
import { useNavigateToForm } from 'utils/useNavigateToForm';

interface MainBannerProps {
  bannerImg: string;
  bannerTablet?: string;
  bannerPhone?: string;
  title: string | string[];
  description?: string;
  button?: string;
}

export const MainBanner = ({
  bannerImg,
  bannerTablet,
  bannerPhone,
  title,
  description,
  button,
}: MainBannerProps) => {
  const isDesktop = useMediaQuery({ minWidth: 901 });
  const isTablet = useMediaQuery({ maxWidth: 900, minWidth: 511 });
  const navigateToForm = useNavigateToForm();
  const chosenBanner = isDesktop ? bannerImg : isTablet ? bannerTablet : bannerPhone;

  return (
    <BoxMain>
      <ImgBanner src={chosenBanner} alt='Banner' loading='eager' />
      <TextBox>
        {Array.isArray(title) ? (
          title.map((line, index) => <h3 key={index}>{line}</h3>)
        ) : (
          <h3>{title}</h3>
        )}{' '}
        <p>{description}</p>
        <Button onClick={navigateToForm} text={button} className='yellow' />
      </TextBox>
    </BoxMain>
  );
};
