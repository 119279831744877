import Container from 'index.style';
import {
  WorkingMechanismBox,
  MechanismBoxText,
  MechanismTag,
  MechanismTitle,
  MechanismListCard,
  MechanismCard,
  MechanismCardNum,
  MechanismCardTitle,
  MechanismCardDescription,
} from './WorkingMechanism.style';
interface CardDetails {
  title: string;
  description: string;
}

interface WorkingMechanismProps {
  tag: string;
  header: string;
  cards: CardDetails[];
}

export const WorkingMechanism = ({ tag, header, cards }: WorkingMechanismProps) => {
  return (
    <Container>
      <WorkingMechanismBox>
        <MechanismBoxText>
          <MechanismTag>{tag}</MechanismTag>
          <MechanismTitle>{header}</MechanismTitle>
        </MechanismBoxText>
        <MechanismListCard>
          {cards.map((card, index) => (
            <MechanismCard key={index}>
              <MechanismCardNum>{index + 1}</MechanismCardNum>
              <MechanismCardTitle>{card.title}</MechanismCardTitle>
              <MechanismCardDescription>{card.description}</MechanismCardDescription>
            </MechanismCard>
          ))}
        </MechanismListCard>
      </WorkingMechanismBox>
    </Container>
  );
};
