import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const InfoBlockBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  margin-bottom: 180px;
  img {
    position: absolute;
    z-index: -1;
    margin-top: -120px;
    width: 100%;
  }
`;

const InfoBoxText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  gap: 20px;
  p {
    color: ${colors.TEAL};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    @media (max-width: 510px) {
      font-size: 14px;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    @media (max-width: 900px) {
      max-width: 624px;
    }
    h3 {
      color: ${colors.CHARCOAL};
      font-size: 44px;
      font-weight: 700;
      max-width: 1000px;
      @media (max-width: 510px) {
        font-size: 28px;
      }
    }
    span {
      color: ${colors.CHARCOAL};
      font-size: 20px;
      font-weight: 400;
    }
  }
  @media (max-width: 900px) {
    margin-top: 166px;
  }
  @media (max-width: 510px) {
    margin-top: 128px;
  }
`;

export { InfoBlockBox, InfoBoxText };
