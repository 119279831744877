import { DataAgreementBlock } from 'components/DataAgreementBlock';
import React from 'react';

const DataAgreementScreen = () => {
  return (
    <>
      <DataAgreementBlock />
    </>
  );
};

export default DataAgreementScreen;
