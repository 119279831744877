import React from 'react';
import { ButtonStyle } from './Button.style';

interface ButtonProps {
  text?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = ({ text, className, onClick, type }: ButtonProps) => {
  return (
    <ButtonStyle type={type} className={className} onClick={onClick}>
      {text}
    </ButtonStyle>
  );
};
