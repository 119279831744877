import { useState, useRef, useEffect } from 'react';
import {
  Menu,
  LogoCompany,
  DropdownContainer,
  DropdownContent,
  StyledLink,
  Arrow,
  BoxSwitcher,
  ButtonBox,
} from './Header.style';
import Container from 'index.style';
import { default as Logo } from 'assets/img/LogoMoneyLink.svg';
import { default as MenuOpen } from 'assets/img/Menu.svg';
import { default as MenuClose } from 'assets/img/MenuClose.svg';
import useClickOutside from 'utils/useClickOutside';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { Button } from 'components/common/Button';
import { handleButtonClick } from 'utils/useNavigateToPlatform';

const validPaths = ['/data_agreement', '/contact', '/user_agreement'];

export const Header = () => {
  const currentScreen = useLocation();
  const currentPath = currentScreen.pathname;
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpenInformation, setIsOpenInformation] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpenInformation(!isOpenInformation);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  useClickOutside(dropdownRef, () => {
    setIsOpenInformation(false);
  });

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  return (
    <Container>
      <Menu>
        <LogoCompany src={Logo} loading='eager' alt='Logo' />
        <ul className={menuOpen ? 'menu active' : 'menu'}>
          <li>
            <StyledLink to='/' onClick={handleLinkClick}>
              {t('headerLanding.main')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              onClick={handleLinkClick}
              className={currentPath === '/business' ? 'current' : 'decoration'}
              to='/business'>
              {t('headerLanding.business')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              onClick={handleLinkClick}
              className={currentPath === '/investor' ? 'current' : 'decoration'}
              to='/investor'>
              {t('headerLanding.investor')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              onClick={handleLinkClick}
              className={currentPath === '/agent' ? 'current' : 'decoration'}
              to='/agent'>
              {t('headerLanding.agent')}
            </StyledLink>
          </li>
          <li>
            <DropdownContainer ref={dropdownRef}>
              <StyledLink
                to='#'
                onClick={handleToggleDropdown}
                className={validPaths.includes(currentPath) ? 'current' : 'decoration'}>
                {t('headerLanding.information')}
                <Arrow data-is-open={isOpenInformation} />
              </StyledLink>

              {isOpenInformation ? (
                <DropdownContent className={isOpenInformation ? 'open' : ''}>
                  <ul>
                    <li>
                      <StyledLink
                        onClick={handleLinkClick}
                        className={currentPath === '/contact' ? 'current' : 'decoration'}
                        to='contact'>
                        {t('headerLanding.informationThree')}
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={handleLinkClick}
                        className={currentPath === '/data_agreement' ? 'current' : 'decoration'}
                        to='data_agreement'>
                        {t('headerLanding.informationFour')}
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={handleLinkClick}
                        className={currentPath === '/user_agreement' ? 'current' : 'decoration'}
                        to='user_agreement'>
                        {t('headerLanding.informationFive')}
                      </StyledLink>
                    </li>
                  </ul>
                </DropdownContent>
              ) : null}
            </DropdownContainer>
          </li>
        </ul>
        <BoxSwitcher>
          <LanguageSwitcher />
          <ButtonBox className='buttons'>
            <Button text={t('headerLanding.buttonEntry')} onClick={handleButtonClick} />
          </ButtonBox>
        </BoxSwitcher>
        <div onClick={() => setMenuOpen(!menuOpen)} className='mobile_btn'>
          {menuOpen ? (
            <img src={MenuClose} alt='icon' width={48} height={48} />
          ) : (
            <img src={MenuOpen} alt='icon' width={48} height={48} />
          )}
        </div>
      </Menu>
    </Container>
  );
};
