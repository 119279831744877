import Container from 'index.style';
import LogoVector from 'assets/img/LogoVector.webp';
import { InfoBlockBox, InfoBoxText } from './InfoBlock.style';
import { Button } from 'components/common/Button';
import { useNavigateToForm } from 'utils/useNavigateToForm';
import { handleButtonClick } from 'utils/useNavigateToPlatform';

interface InfoBlockProps {
  tag: string;
  title: string;
  description: string;
  button: string;
}

export const InfoBlock = ({ tag, title, description, button }: InfoBlockProps) => {
  return (
    <Container>
      <InfoBlockBox>
        <img src={LogoVector} alt='logo-vector' loading='lazy' />
        <InfoBoxText>
          <p>{tag}</p>
          <div>
            <h3>{title}</h3>
            <span>{description}</span>
            <Button onClick={handleButtonClick} text={button} className='yellow' />
          </div>
        </InfoBoxText>
      </InfoBlockBox>
    </Container>
  );
};
