import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxSelect = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  h3 {
    color: ${colors.CHARCOAL};
    font-size: 14px;
    font-weight: 600;
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 24px;
    background: ${colors.LIGHT_GRAY};
    border: none;
    color: ${colors.CHARCOAL};
    font-size: 14px;
    font-weight: 400;
    border-radius: 60px;
    cursor: pointer;
    position: relative;
  }

  .arrow-down,
  .arrow-up {
    width: 10px;
    height: 10px;
    transition: transform 0.3s ease;
  }

  .arrow-up {
    transform: rotate(180deg);
  }

  .options {
    display: none;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    width: 100%;
    top: 100%;
    background: ${colors.WHITE};
    z-index: 2;
    border-radius: 0px 0px 0px 20px;
    border: 1px solid var(--light-grey, ${colors.SOFT_GRAY});
    color: ${colors.CHARCOAL};
    font-size: 14px;
    font-weight: 400;
    max-height: 210px;
    overflow-y: auto;
  }

  .option {
    padding: 10px 24px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.LIGHT_GRAY};
    }
  }

  &.open .options {
    display: flex;
  }

  .options::-webkit-scrollbar {
    width: 4px; /* ширина для вертикального скроллбара */
    height: 4px; /* высота для горизонтального скроллбара */
  }

  .options::-webkit-scrollbar-button {
    display: none; /* скройте стрелки */
  }

  .options::-webkit-scrollbar-thumb {
    background: ${colors.CHARCOAL}; /* цвет ползунка */
  }

  .options::-webkit-scrollbar-thumb:hover {
    background: ${colors.TEAL}; /* цвет ползунка при наведении */
  }

  .options::-webkit-scrollbar-track {
    background: ${colors.SOFT_GRAY};
    border-radius: 4px;
  }

  .options::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export { BoxSelect };
