import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const FaqBlockBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 60px;
  align-items: center;
  gap: 40px;
  border-radius: 14px;
  background: ${colors.WHITE};
  @media (max-width: 700px) {
    padding: 60px 20px;
  }
`;

const FaqBoxText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const FaqBoxTextTag = styled.p`
  color: ${colors.GOLDEN};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`;

const FaqBoxTextTitle = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 44px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 28px;
  }
`;

const FaqBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;

const QuestionRow = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 10px;
  background: ${({ $isOpen }) => ($isOpen ? `${colors.TEAL}` : `${colors.LIGHT_GRAY}`)};
  padding: 18px 24px;

  p {
    color: ${({ $isOpen }) => ($isOpen ? `${colors.WHITE}` : `${colors.CHARCOAL}`)};
    font-size: 18px;
    font-weight: 600;
  }
  span {
    color: ${({ $isOpen }) => ($isOpen ? `${colors.WHITE}` : `${colors.CHARCOAL}`)};
    font-size: 24px;
    font-weight: 400;
    margin-left: 8px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const AnswerBox = styled.div`
  padding: 18px 24px;
  background-color: ${colors.LIGHT_GRAY};
  border-radius: 10px;
  color: ${colors.CHARCOAL};
  font-size: 18px;
  font-weight: 400;
`;

export {
  FaqBlockBox,
  FaqBoxText,
  FaqBoxTextTag,
  FaqBoxTextTitle,
  FaqBoxContent,
  QuestionRow,
  Box,
  AnswerBox,
};
