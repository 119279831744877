import styled from 'styled-components';
import colors from 'global_styling/colors';
import { Link } from 'react-router-dom';
import { ReactComponent as IconArrow } from 'assets/img/IconArrow.svg';

const Menu = styled.menu`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
  width: 100%;
  height: 48px;
  /* justify-content: space-between; */
  gap: 42px;
  .current {
    color: ${colors.TEAL};
  }
  @media (max-width: 670px) {
    display: grid;
    grid-template-columns: 18fr 4fr 2fr;
    grid-template-columns: 48fr 0fr 0fr;
    gap: 30px;
  }
  li {
    cursor: pointer;
  }
  .menu {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    gap: 32px;
  }
  .mobile_btn {
    display: none;
  }
  @media (max-width: 1120px) {
    .mobile_btn {
      display: block;
      position: absolute;
      right: 10px;
      z-index: 10;
      cursor: pointer;
      @media (max-width: 670px) {
        position: relative;
        right: 0;
      }
    }
    .menu {
      display: none;
      flex-direction: column;
      position: fixed;
      right: -100%;
      top: 100px;
      width: 100%;
      height: calc(100% - 100px);
      z-index: 10;
      transition: right 0.5s;
      gap: 84px;
      overflow: auto;
      padding: 13% 60px 60px 60px;
      visibility: visible;
      background-color: #f3f4f6;
      text-align: start;
      @media (max-width: 670px) {
        padding: 20% 20px;
        gap: 60px;
      }
    }
    .active {
      right: 0;
      display: flex;
    }
  }
`;

const LogoCompany = styled.img`
  margin-right: 40px;
  width: 116px;
  height: 32px;
  @media (max-width: 1174px) {
    margin-right: 10px;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  .buttons {
    @media (max-width: 1120px) {
      margin-right: 100px;
    }
    @media (max-width: 670px) {
      display: none;
    }
  }
`;

const BoxSwitcher = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  .buttons {
    @media (max-width: 1120px) {
      margin-right: 100px;
    }
    @media (max-width: 670px) {
      display: none;
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  z-index: 999;
  background-color: ${colors.LIGHT_GRAY};
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  margin-top: 10px;

  cursor: pointer;
  li {
    line-height: 30px;
  }
  &.open {
    display: block;
  }
  @media (max-width: 1120px) {
    position: relative;
    box-shadow: none;
    background-image: linear-gradient(to right, ${colors.WHITE}, #f3f4f6);
    margin-top: 30px;
    border-radius: 30px;
    border: 2px solid ${colors.TEAL};
    box-shadow: 0px 8px 26px 0px rgba(0, 128, 128, 0.3);
    li {
      margin-top: 20px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.CHARCOAL};
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  @media (max-width: 1120px) {
    font-size: 24px;
  }

  &:hover {
    color: ${colors.TEAL};
  }
`;

const Arrow = styled(IconArrow)<{ 'data-is-open': boolean }>`
  display: inline-block;
  transform: ${(props) => (props['data-is-open'] ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  margin-left: 6px;
  @media (max-width: 1120px) {
    width: 20px;
    height: 20px;
    margin-bottom: -2px;
    margin-left: 10px;
  }
`;

const ButtonMobileBox = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 50px;
  @media (max-width: 670px) {
    display: flex;
  }
  @media (max-width: 420px) {
    margin-top: 50px;
  }
`;

export {
  Menu,
  LogoCompany,
  ButtonBox,
  DropdownContainer,
  BoxSwitcher,
  DropdownContent,
  StyledLink,
  Arrow,
  ButtonMobileBox,
};
