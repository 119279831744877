import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MainBanner } from 'components/MainBanner';
import { ExplanationBlock } from 'components/ExplanationBlock';
import { WorkingMechanism } from 'components/WorkingMechanism';
import { AdvantagesBlock } from 'components/AdvantagesBlock';
import { FaqBlock } from 'components/FaqBlock';
import { InfoBlock } from 'components/InfoBlock';
import { CalculatorReward } from 'components/CalculatorReward';
import IconMoney from 'assets/img/IconMoneyGold.webp';
import IconCursor from 'assets/img/IconCursor.webp';
import IconCalendar from 'assets/img/IconCalendar.webp';
import IconPercent from 'assets/img/IconPercent.webp';
import Banner from 'assets/img/AgentMainBanner.webp';
import TabletBanner from 'assets/img/AgentMainTablet.webp';
import PhoneBanner from 'assets/img/AgentMainPhone.webp';

const getFaqQuestions = (t: TFunction) => [
  {
    question: t('faqBlockAgent.questionOne'),
    answer: t('faqBlockAgent.answerOne'),
  },
  // {
  //   question: t('faqBlock.questionTwo'),
  //   answer: t('faqBlock.answerTwo'),
  // },
  // {
  //   question: t('faqBlock.questionThree'),
  //   answer: t('faqBlock.answerThree'),
  // },
  // {
  //   question: t('faqBlock.questionFour'),
  //   answer: t('faqBlock.answerFour'),
  // },
];

const getAdvantagesData = (t: TFunction) => [
  {
    title: t('advantagesBlockAgent.cardOneTitle'),
    description: t('advantagesBlockAgent.cardOneDescription'),
    icon: IconCursor,
  },
  {
    title: t('advantagesBlockAgent.cardTwoTitle'),
    description: t('advantagesBlockAgent.cardTwoDescription'),
    icon: IconMoney,
  },
  {
    title: t('advantagesBlockAgent.cardThreeTitle'),
    description: t('advantagesBlockAgent.cardThreeDescription'),
    icon: IconCalendar,
  },
  {
    title: t('advantagesBlockAgent.cardFourTitle'),
    description: t('advantagesBlockAgent.cardFourDescription'),
    icon: IconPercent,
  },
];

const getWorkingMechanismData = (t: TFunction) => [
  {
    title: t('workingMechanismBlockAgent.cardOneTitle'),
    description: t('workingMechanismBlockAgent.cardOneDescription'),
  },
  {
    title: t('workingMechanismBlockAgent.cardTwoTitle'),
    description: t('workingMechanismBlockAgent.cardTwoDescription'),
  },
  {
    title: t('workingMechanismBlockAgent.cardThreeTitle'),
    description: t('workingMechanismBlockAgent.cardThreeDescription'),
  },
];

const AgentScreen = () => {
  const { t } = useTranslation();

  const questions = useMemo(() => getFaqQuestions(t), [t]);
  const advantagesData = useMemo(() => getAdvantagesData(t), [t]);
  const workingMechanismData = useMemo(() => getWorkingMechanismData(t), [t]);

  return (
    <>
      <MainBanner
        bannerImg={Banner}
        bannerTablet={TabletBanner}
        bannerPhone={PhoneBanner}
        title={t('mainAgentBanner.title')}
        description={t('mainAgentBanner.description')}
        button={t('mainAgentBanner.button')}
      />
      <ExplanationBlock
        tag={t('explanationBlockAgent.tag')}
        title={t('explanationBlockAgent.title')}
        description={t('explanationBlockAgent.description')}
      />
      <WorkingMechanism
        tag={t('workingMechanismBlockAgent.tag')}
        header={t('workingMechanismBlockAgent.header')}
        cards={workingMechanismData}
      />
      <AdvantagesBlock
        tag={t('advantagesBlockAgent.tag')}
        title={t('advantagesBlockAgent.title')}
        description={t('advantagesBlockAgent.description')}
        advantages={advantagesData}
      />

      <CalculatorReward />
      <FaqBlock
        tag={t('faqBlockAgent.tag')}
        title={t('faqBlockAgent.title')}
        questions={questions}
      />
      <InfoBlock
        tag={t('infoBlockAgent.tag')}
        title={t('infoBlockAgent.title')}
        description={t('infoBlockAgent.description')}
        button={t('infoBlockAgent.button')}
      />
    </>
  );
};

export default AgentScreen;
