import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const AdvantagesBlockBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin-top: 140px;
  width: 100%;
  @media (max-width: 510px) {
    margin-top: 80px;
  }
`;

const AdvantagesBoxText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const AdvantagesBoxTag = styled.p`
  color: ${colors.TEAL};
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  @media (max-width: 510px) {
    font-size: 14px;
  }
`;

const AdvantagesBoxTitle = styled.h3`
  color: ${colors.CHARCOAL};
  font-size: 44px;
  font-weight: 700;
  @media (max-width: 900px) {
    font-size: 40px;
  }
  @media (max-width: 510px) {
    font-size: 28px;
  }
`;

const AdvantagesBoxDescription = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const AdvantagesListCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 22px;
`;

const AdvantagesCard = styled.div`
  display: flex;
  padding: 32px 28px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 28px;
  border-radius: 10px;
  background: ${colors.WHITE};
  max-width: 260px;
  @media (max-width: 1140px) {
    max-width: calc(50% - 22px); /* По две карточки в ряд на устройствах до 768px */
  }

  @media (max-width: 580px) {
    max-width: 100%; /* По одной карточке на устройствах до 480px */
  }
`;

const AdvantagesCardIcon = styled.img`
  width: 60px;
  height: 60px;
`;

const AdvantagesCardTitle = styled.p`
  color: ${colors.CHARCOAL};
  font-size: 20px;
  font-weight: 600;
  min-height: 50px;
  @media (max-width: 1140px) {
    min-height: 0;
  }
`;
const AdvantagesCardDescription = styled.p`
  color: ${colors.GRAY};
  font-size: 18px;
  font-weight: 400;
`;

export {
  AdvantagesBlockBox,
  AdvantagesBoxText,
  AdvantagesBoxTag,
  AdvantagesBoxTitle,
  AdvantagesBoxDescription,
  AdvantagesListCard,
  AdvantagesCard,
  AdvantagesCardIcon,
  AdvantagesCardTitle,
  AdvantagesCardDescription,
};
