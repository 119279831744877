import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigateToForm } from 'utils/useNavigateToForm';
import { Button } from 'components/common/Button';
import Container from 'index.style';
import {
  ListCardBusiness,
  BannerBoxBusiness,
  BannerBoxText,
  InfoBoxBusiness,
  InfoCardBusiness,
  Arrow,
} from './BusinessBlock.style';
import { handleButtonClick } from 'utils/useNavigateToPlatform';

export const BusinessBlock = () => {
  const { t } = useTranslation();

  const infoCardsData = useMemo(
    () => [
      { titleKey: 'cardTitleOne', infoKey: 'cardInfoOne' },
      { titleKey: 'cardTitleTwo', infoKey: 'cardInfoTwo' },
      { titleKey: 'cardTitleThree', infoKey: 'cardInfoThree' },
      { titleKey: 'cardTitleFour', infoKey: 'cardInfoFour' },
    ],
    [],
  );

  return (
    <Container>
      <ListCardBusiness>
        <BannerBoxBusiness>
          <BannerBoxText>
            <span>{t('businessBlock.bannerTag')}</span>
            <h3>{t('businessBlock.bannerTitle')}</h3>
            <Button
              onClick={handleButtonClick}
              text={t('businessBlock.bannerButton')}
              className='yellow'
            />
          </BannerBoxText>
        </BannerBoxBusiness>
        <InfoBoxBusiness>
          {infoCardsData.map(({ titleKey, infoKey }, index) => (
            <InfoCardBusiness key={index}>
              <Arrow />
              <h4>{t(`businessBlock.${titleKey}`)}</h4>
              <p>{t(`businessBlock.${infoKey}`)}</p>
            </InfoCardBusiness>
          ))}
        </InfoBoxBusiness>
      </ListCardBusiness>
    </Container>
  );
};
