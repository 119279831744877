import { styled } from 'styled-components';
import FeedbackBanner from 'assets/img/FeedbackBanner.webp';
import colors from 'global_styling/colors';

const FeedbackBox = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  background-image: url(${FeedbackBanner});
  background-size: cover;
  border-radius: 34px;
  margin-top: 214px;
  margin-bottom: -70px;
  @media (max-width: 900px) {
    margin-top: 166px;
  }
  @media (max-width: 510px) {
    margin-top: 128px;
  }
`;

const FeedbackBoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.WHITE};
  gap: 24px;
  padding: 95px 63px;
  width: 100%;
  text-align: center;
  @media (max-width: 510px) {
    padding: 80px 24px;
  }
`;

const FeedbackTag = styled.p`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 510px) {
    font-size: 14px;
  }
`;
const FeedbackTitle = styled.h4`
  font-size: 44px;
  font-weight: 700;
  @media (max-width: 510px) {
    font-size: 28px;
  }
`;

const FeedbackDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  @media (max-width: 510px) {
    font-size: 16px;
  }
`;

export { FeedbackBox, FeedbackBoxText, FeedbackTag, FeedbackTitle, FeedbackDescription };
