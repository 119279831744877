import Container from 'index.style';
import React from 'react';
import {
  BoxExplanation,
  BoxExplanationMain,
  ExplanationTag,
  ExplanationTitle,
  ExplanationDescription,
} from './ExplanationBlock.style';

interface ExplanationBlockProps {
  tag: string;
  title: string;
  description: string;
}

export const ExplanationBlock = ({ tag, title, description }: ExplanationBlockProps) => {
  return (
    <Container>
      <BoxExplanation>
        <BoxExplanationMain>
          <ExplanationTag>{tag}</ExplanationTag>
          <ExplanationTitle>{title}</ExplanationTitle>
        </BoxExplanationMain>
        <ExplanationDescription>{description}</ExplanationDescription>
      </BoxExplanation>
    </Container>
  );
};
