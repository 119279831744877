import React from 'react';
import { ContactBlock } from 'components/ContactBlock';
import { MyMapRenderer } from 'components/GoogleMaps';
import { ContactForm } from 'components/ContactForm';

const API_KEY = 'AIzaSyCfyeiGwS-DNQn_58nZMcBE5gS1JttNaiw';

const ContactScreen = () => {
  return (
    <>
      <ContactBlock />
      <MyMapRenderer apiKey={API_KEY} />
      <ContactForm />
    </>
  );
};

export default ContactScreen;
