import React from 'react';
import { InputBox, Input, ErrorMessage } from './InputText.style';

enum InputTextType {
  TEXT = 'text',
  PHONE = 'tel',
  EMAIL = 'email',
}

interface InputTextProps {
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  type?: string;
  placeholder?: string;
}

export const InputText = ({
  type = InputTextType.TEXT,
  placeholder,
  value,
  onChange,
  error,
}: InputTextProps) => {
  const hasError = Boolean(error);
  return (
    <>
      <InputBox>
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          $hasError={hasError}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </InputBox>
    </>
  );
};
