import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  /* @media (max-width: 1824px) {
    max-width: 1200px;
  } */
`;

export default Container;
