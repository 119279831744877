import React, { useRef, useEffect, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Container from 'index.style';
import IconMarker from 'assets/img/favicon.ico';

function GoogleMaps({ center, zoom }: { center: google.maps.LatLngLiteral; zoom: number }) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (ref.current) {
      const createdMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
      setMap(createdMap);
    }
  }, [center, zoom]);

  const markerIcon: google.maps.Icon = {
    url: IconMarker,
    scaledSize: new window.google.maps.Size(30, 30),
  };

  useEffect(() => {
    if (map) {
      new window.google.maps.Marker({
        position: center,
        map: map,
        icon: markerIcon,
      });
    }
  }, [map, center]);

  return <div ref={ref} style={{ width: '100%', height: '400px' }} />;
}

export function MyMapRenderer({ apiKey }: { apiKey: string }) {
  const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <div>Loading...</div>;
      case Status.FAILURE:
        return <div>Error loading map</div>;
      case Status.SUCCESS:
        return <GoogleMaps center={{ lat: 51.087279, lng: 71.416894 }} zoom={16} />;
      default:
        return <div>Loading...</div>;
    }
  };

  return (
    <Container>
      <Wrapper apiKey={apiKey} render={render} />
    </Container>
  );
}
