import axios from 'axios';

interface IFormPayload {
  name: string;
  message: string;
  phone: string;
}

const urlProd = 'https://moneylink.kz/';
const urlDev = 'https://qa.moneylink.kz/';

const baseURL = process.env.REACT_APP_MODE === 'prod' ? urlProd : urlDev;

const axiosInstanceJSON = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendFormFeedback = (form: IFormPayload) => {
  return axiosInstanceJSON.post('api/v1/public/feedback', form);
};
