import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Container from 'index.style';
import {
  Box,
  SearchBox,
  HeaderBox,
  TextInfo,
  TextHeader,
  InputBox,
  InputContract,
  BoxResponseContract,
  TitleResponseContract,
  InfoResponseContract,
  ButtonDowland,
} from './CheckContract.style';
import { ContractData } from './CheckContract.interface';
import loadingGif from 'assets/img/IphoneGif.gif';
import { useLocation } from 'react-router-dom';

const urlProd = 'https://moneylink.kz/';
const urlDev = 'https://qa.moneylink.kz/';

const baseURL = process.env.REACT_APP_MODE === 'prod' ? urlProd : urlDev;

export const CheckContract = () => {
  const [contractNum, setContractNum] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataContract, setDataContract] = useState<ContractData | null>(null);
  const [error, setError] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const contractNumber = queryParams.get('contractNumber');
    if (contractNumber) {
      setContractNum(contractNumber);
      fetchPdf(contractNumber);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [location]);

  const handleInputChange = (e: any) => {
    setContractNum(e.target.value);
  };
  const fetchPdf = async (num = contractNum) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${baseURL}public/api/v1/ui/doc/receipt?num=${num}`, {
        withCredentials: true,
      });
      setDataContract(response.data);
    } catch (error) {
      setContractNum('');
      setError('Код не найден');
    } finally {
      setLoading(false);
    }
  };

  const downloadPdf = async () => {
    try {
      const response = await axios.get(
        `${baseURL}public/api/v1/ui/doc/receipt/generate?contractNumber=${contractNum}`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const downloadLink = document.createElement('a');
      downloadLink.href = fileURL;
      downloadLink.setAttribute('download', `contract-${contractNum}.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      setError('Failed to download PDF');
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchPdf();
  };

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return new Date(dateString).toLocaleDateString('ru-RU', options).replace(/\./g, ' ');
  };

  return (
    <Container>
      <Box>
        <SearchBox>
          <HeaderBox>
            <TextInfo>онлайн инструменты</TextInfo>
            <TextHeader>Проверить статус договора</TextHeader>
          </HeaderBox>
          <form onSubmit={handleSubmit}>
            <InputBox $isError={!!error}>
              <InputContract>
                <div>Пример: RT-071</div>
                <input
                  ref={inputRef}
                  type='text'
                  placeholder={error ? 'Код не найден' : 'Введите код'}
                  onChange={handleInputChange}
                  value={contractNum}
                />
              </InputContract>
              <button type='submit'>Проверить документ</button>
            </InputBox>
          </form>
        </SearchBox>
        {loading && (
          <div>
            <img src={loadingGif} alt='Loading...' />
          </div>
        )}
        {!loading && !error && dataContract && (
          <>
            <BoxResponseContract>
              <TitleResponseContract>Основная информация</TitleResponseContract>
              <InfoResponseContract>
                <strong>№Договора</strong> {dataContract?.contractNumber}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Тип документа</strong> {dataContract?.docType}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Название</strong> {dataContract?.docName}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Статус</strong>{' '}
                {dataContract?.status === 'SIGNED' ? 'Подписан' : 'Не подписан'}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Рег.номер</strong> {dataContract?.regNumber}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Рег.дата</strong> {formatDate(dataContract?.regDate || 'N/A')}
              </InfoResponseContract>
              <TitleResponseContract>Информация об инвесторе</TitleResponseContract>
              <InfoResponseContract>
                <strong>Инвестор</strong> {dataContract?.investor.name}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>ИИН/БИН</strong> {dataContract?.investor.iinOrBin}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Адрес</strong> {dataContract?.investor.address}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>E-mail</strong> {dataContract?.investor.email}
              </InfoResponseContract>
              <TitleResponseContract>Информация о заемщике</TitleResponseContract>
              <InfoResponseContract>
                <strong>Инвестор</strong> {dataContract?.business.name}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>ИИН/БИН</strong> {dataContract?.business.iinOrBin}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Адрес</strong> {dataContract?.business.address}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>E-mail</strong> {dataContract?.business.email}
              </InfoResponseContract>
              <TitleResponseContract>Информация о подписанте</TitleResponseContract>
              <InfoResponseContract>
                <strong>Подписал(а)</strong>{' '}
                {dataContract?.investorSign?.signerName || 'Не указано'}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Компания</strong> {dataContract?.investorSign?.signerCompany}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Должность</strong> {dataContract?.investorSign?.signerPosition}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Тип ЭЦП</strong> {dataContract?.investorSign?.edsType}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Подпись</strong> {dataContract?.investorSign?.eds}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Дата подписания</strong>{' '}
                {formatDate(dataContract?.investorSign?.signDate || 'N/A')}
              </InfoResponseContract>
              <TitleResponseContract>Информация о подписанте</TitleResponseContract>
              <InfoResponseContract>
                <strong>Подписал(а)</strong>{' '}
                {dataContract?.businessSign?.signerName || 'Не указано'}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Компания</strong> {dataContract?.businessSign?.signerCompany}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Должность</strong> {dataContract?.businessSign?.signerPosition}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Тип ЭЦП</strong> {dataContract?.businessSign?.edsType}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Подпись</strong> {dataContract?.businessSign?.eds}
              </InfoResponseContract>
              <InfoResponseContract>
                <strong>Дата подписания</strong>{' '}
                {formatDate(dataContract?.businessSign?.signDate || 'N/A')}
              </InfoResponseContract>
            </BoxResponseContract>
            <ButtonDowland onClick={downloadPdf}>Скачать PDF</ButtonDowland>
          </>
        )}
      </Box>
    </Container>
  );
};
