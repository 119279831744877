import { styled } from 'styled-components';
import CalculateCart from 'assets/img/CalculateCart.webp';
import colors from 'global_styling/colors';

const CartBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-image: url(${CalculateCart});
  background-size: cover;
  border-radius: 14px;
  box-shadow: 0px 34px 64px 0px rgba(1, 174, 183, 0.3);
  gap: 56px;
  padding: 68px 40px 40px 40px;
  @media (max-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

const CartBoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.WHITE};
`;

const CartHeaderTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

const CartHeaderSum = styled.p`
  font-size: 42px;
  font-weight: 700;
`;

const CartBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const CartInfoKeyValue = styled.div`
  display: flex;
  font-size: 16px;
  color: ${colors.WHITE};
  justify-content: space-between;
  gap: 10px;
`;

const CartInfoKey = styled.p`
  font-weight: 400;
  max-width: 170px;
`;

const CartInfoValue = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

export {
  CartBox,
  CartBoxHeader,
  CartHeaderTitle,
  CartHeaderSum,
  CartBoxInfo,
  CartInfoKeyValue,
  CartInfoKey,
  CartInfoValue,
};
