import styled from 'styled-components';
import colors from 'global_styling/colors';

const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 40px;
  position: fixed;
  background: ${colors.CHARCOAL};
  opacity: 0.9;
  justify-content: center;
  bottom: 0px;
  width: 100%;
  z-index: 9999;
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 12px 24px;
    text-align: center;
    gap: 12px;
  }
`;
const Title = styled.p`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.2px;
  color: ${colors.WHITE};
  max-width: 980px;
`;

export { Box, Title };
